import {
  getMeDashboardWidgets,
} from "@/api/repositories/dashboardRepository";
import { getAllowedDashboardWidgets } from "@/config/dashboard-widgets";

const initialState = () => ({
  userWidgets: {
    value: [],
    isInited: false,
  },
  allowedWidgets: {
    value: [],
    isInited: false,
  },
});

export default {
  state: initialState(),
  getters: {
    userWidgets: (state) => {
      return state.userWidgets.value;
    },
    allowedWidgets: (state) => {
      return state.allowedWidgets.value;
    },
  },
  mutations: {
    SET_USER_WIDGETS_DATA(state, data = {}) {
      Object.assign(state.userWidgets, data);
    },
    SET_ALLOWED_WIDGETS_DATA(state, data = []) {
      Object.assign(state.allowedWidgets, data);
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async fetchUserWidgets({ commit }) {
      const response = await getMeDashboardWidgets();
      const userWidgets = response?.data || [];

      commit('SET_USER_WIDGETS_DATA', {
        value: userWidgets,
      });

      return userWidgets;
    },
    async initUserWidgets({ state, commit, dispatch }) {
      if (state.userWidgets.isInited) {
        return;
      }

      await dispatch('fetchUserWidgets');
      commit('SET_USER_WIDGETS_DATA', {
        isInited: true,
      });
    },
    async initAllowedWidgets({ state, commit }) {
      if (state.allowedWidgets.isInited) {
        return;
      }

      commit('SET_ALLOWED_WIDGETS_DATA', {
        value: getAllowedDashboardWidgets(),
        isInited: true,
      });
    },
  },
  namespaced: true,
};
